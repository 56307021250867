<template>
  <div class="timebox">
    <div class="timebox-items -local">
      <div class="timebox--lbl">
        <span>Local</span>
      </div>
      <div class="timebox--data">
        <span>12/12/12 12:12:12</span>
      </div>
    </div>

    <div class="timebox-items -timezone">
      <div class="timebox--lbl">
        <select-list title="GMT + 8">
          <select-option
            value="1"
            name="GMT+1"
          >
            GMT+1
          </select-option>
          <select-option
            value="2"
            name="GMT+2"
          >
            GMT+2
          </select-option>
        </select-list>
      </div>

      <div class="timebox--data">
        <span>12/12/12 12:12:12</span>
      </div>
    </div>
  </div>
</template>

<script>
//= components
import '@/components/base/select/Select'

export default {
  name: 'timebox'
}
</script>
